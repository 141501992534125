{
  "name": "enydea-dlcr-webapp",
  "version": "0.2.13",
  "engines": {
    "node": "18 || 20 || 22"
  },
  "scripts": {
    "ng": "ng",
    "start": "ng serve --port 4401",
    "build": "ng build --configuration production",
    "watch": "ng build --watch --configuration development",
    "updateversion": "npm version patch",
    "prebuild": "ng build",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "serve:ssr:enydea-dlcr-webapp": "node /Users/paulsombekke/Development/enydea/enydea-dlcr/client/server/server.mjs"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.6",
    "@angular/cdk": "^18.2.6",
    "@angular/common": "^18.2.6",
    "@angular/compiler": "^18.2.6",
    "@angular/core": "^18.2.6",
    "@angular/flex-layout": "^15.0.0-beta.42",
    "@angular/forms": "^18.2.6",
    "@angular/localize": "^18.2.6",
    "@angular/material": "^18.2.6",
    "@angular/platform-browser": "^18.2.6",
    "@angular/platform-browser-dynamic": "^18.2.6",
    "@angular/platform-server": "^18.2.6",
    "@angular/router": "^18.2.6",
    "@angular/ssr": "^18.2.6",
    "@ctrl/ngx-emoji-mart": "^9.2.0",
    "@pixi/filter-cross-hatch": "^5.1.1",
    "@pixi/filter-glitch": "^5.1.1",
    "@pixi/filter-old-film": "^5.1.1",
    "@pixi/filter-shockwave": "^5.1.1",
    "@svgdotjs/svg.js": "^3.2.4",
    "@types/gsap": "^3.0.0",
    "chart.js": "^4.4.4",
    "core-js": "^3.8.1",
    "countup.js": "^2.0.7",
    "express": "^4.18.2",
    "gsap": "^3.12.5",
    "ng2-charts": "^6.0.0",
    "observable-webworker": "^4.0.1",
    "pixi.js": "^7.4.2",
    "postprocessing": "^6.29.1",
    "recaptcha-v3": "^1.11.3",
    "rxjs": "^7.8.1",
    "socket.io-client": "^4.8.0",
    "ssr-window": "^4.0.2",
    "svgson": "^5.2.1",
    "tslib": "^2.0.0",
    "twemoji": "^14.0.2",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.6",
    "@angular/cli": "^18.2.6",
    "@angular/compiler-cli": "^18.2.6",
    "@angular/language-service": "^18.2.6",
    "@types/express": "^5.0.0",
    "@types/jasmine": "~5.1.4",
    "@types/node": "^18.0.0",
    "@types/socket.io-client": "^1.4.34",
    "angular2-draggable": "^16.0.0",
    "browser-sync": "^3.0.3",
    "jasmine-core": "~5.3.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "protractor": "~7.0.0",
    "ts-node": "~10.9.2",
    "tslint": "~6.1.0",
    "typescript": ">=5.4 and <5.6"
  }
}
