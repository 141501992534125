/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Formsend } from '../models/formsend';
import { getform } from '../fn/formsends-controller/getform';
import { Getform$Params } from '../fn/formsends-controller/getform';
import { send } from '../fn/formsends-controller/send';
import { Send$Params } from '../fn/formsends-controller/send';
import { tokenvalidate } from '../fn/formsends-controller/tokenvalidate';
import { Tokenvalidate$Params } from '../fn/formsends-controller/tokenvalidate';

@Injectable({ providedIn: 'root' })
export class FormsendsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `formsendsControllerSend()` */
  static readonly FormsendsControllerSendPath = '/formsends/send';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `send()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  send$Response(params?: Send$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return send(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `send$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  send(params?: Send$Params, context?: HttpContext): Observable<void> {
    return this.send$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `formsendsControllerTokenvalidate()` */
  static readonly FormsendsControllerTokenvalidatePath = '/formsends/tokenvalidate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tokenvalidate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tokenvalidate$Response(params?: Tokenvalidate$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'success'?: string;
'message'?: string;
}>> {
    return tokenvalidate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tokenvalidate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tokenvalidate(params?: Tokenvalidate$Params, context?: HttpContext): Observable<{
'success'?: string;
'message'?: string;
}> {
    return this.tokenvalidate$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'success'?: string;
'message'?: string;
}>): {
'success'?: string;
'message'?: string;
} => r.body)
    );
  }

  /** Path part for operation `formsendsControllerGetform()` */
  static readonly FormsendsControllerGetformPath = '/formsends/{id}/getform';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getform()` instead.
   *
   * This method doesn't expect any request body.
   */
  getform$Response(params: Getform$Params, context?: HttpContext): Observable<StrictHttpResponse<Formsend>> {
    return getform(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getform$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getform(params: Getform$Params, context?: HttpContext): Observable<Formsend> {
    return this.getform$Response(params, context).pipe(
      map((r: StrictHttpResponse<Formsend>): Formsend => r.body)
    );
  }

}
