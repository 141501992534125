/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface Convertthreed$Params {
  id: string;
  url?: string;
  name?: string;
  ext?: string;
  companyid?: string;
}

export function convertthreed(http: HttpClient, rootUrl: string, params: Convertthreed$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
  const rb = new RequestBuilder(rootUrl, convertthreed.PATH, 'post');
  if (params) {
    rb.path('id', params.id, {});
    rb.query('url', params.url, {});
    rb.query('name', params.name, {});
    rb.query('ext', params.ext, {});
    rb.query('companyid', params.companyid, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<any>;
    })
  );
}

convertthreed.PATH = '/video/{id}/convertthreed';
