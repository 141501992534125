/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface Combinevideos$Params {
  relationsid: string;
  companyid?: string;
  fileid?: string;
      body?: Array<any>
}

export function combinevideos(http: HttpClient, rootUrl: string, params: Combinevideos$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
  const rb = new RequestBuilder(rootUrl, combinevideos.PATH, 'post');
  if (params) {
    rb.path('relationsid', params.relationsid, {});
    rb.query('companyid', params.companyid, {});
    rb.query('fileid', params.fileid, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<any>;
    })
  );
}

combinevideos.PATH = '/video/{id}/combinevideos';
