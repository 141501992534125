/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { combineVideo } from '../fn/videorender-controller/combine-video';
import { CombineVideo$Params } from '../fn/videorender-controller/combine-video';
import { combinevideos } from '../fn/videorender-controller/combinevideos';
import { Combinevideos$Params } from '../fn/videorender-controller/combinevideos';
import { converteps2svg } from '../fn/videorender-controller/converteps-2-svg';
import { Converteps2svg$Params } from '../fn/videorender-controller/converteps-2-svg';
import { convertthreed } from '../fn/videorender-controller/convertthreed';
import { Convertthreed$Params } from '../fn/videorender-controller/convertthreed';
import { convertVideo2mp4 } from '../fn/videorender-controller/convert-video-2-mp-4';
import { ConvertVideo2mp4$Params } from '../fn/videorender-controller/convert-video-2-mp-4';
import { creategif } from '../fn/videorender-controller/creategif';
import { Creategif$Params } from '../fn/videorender-controller/creategif';
import { createvideo } from '../fn/videorender-controller/createvideo';
import { Createvideo$Params } from '../fn/videorender-controller/createvideo';
import { screenshot } from '../fn/videorender-controller/screenshot';
import { Screenshot$Params } from '../fn/videorender-controller/screenshot';

@Injectable({ providedIn: 'root' })
export class VideorenderControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `videorenderControllerCombineVideo()` */
  static readonly VideorenderControllerCombineVideoPath = '/video/{id}/combineVideo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `combineVideo()` instead.
   *
   * This method doesn't expect any request body.
   */
  combineVideo$Response(params: CombineVideo$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return combineVideo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `combineVideo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  combineVideo(params: CombineVideo$Params, context?: HttpContext): Observable<any> {
    return this.combineVideo$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `videorenderControllerCombinevideos()` */
  static readonly VideorenderControllerCombinevideosPath = '/video/{id}/combinevideos';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `combinevideos()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  combinevideos$Response(params: Combinevideos$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return combinevideos(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `combinevideos$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  combinevideos(params: Combinevideos$Params, context?: HttpContext): Observable<any> {
    return this.combinevideos$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `videorenderControllerConvertVideo2Mp4()` */
  static readonly VideorenderControllerConvertVideo2Mp4Path = '/video/{id}/convertVideo2mp4';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `convertVideo2mp4()` instead.
   *
   * This method doesn't expect any request body.
   */
  convertVideo2mp4$Response(params?: ConvertVideo2mp4$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return convertVideo2mp4(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `convertVideo2mp4$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  convertVideo2mp4(params?: ConvertVideo2mp4$Params, context?: HttpContext): Observable<any> {
    return this.convertVideo2mp4$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `videorenderControllerConverteps2Svg()` */
  static readonly VideorenderControllerConverteps2SvgPath = '/video/{id}/converteps2svg';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `converteps2svg()` instead.
   *
   * This method doesn't expect any request body.
   */
  converteps2svg$Response(params: Converteps2svg$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return converteps2svg(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `converteps2svg$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  converteps2svg(params: Converteps2svg$Params, context?: HttpContext): Observable<any> {
    return this.converteps2svg$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `videorenderControllerConvertthreed()` */
  static readonly VideorenderControllerConvertthreedPath = '/video/{id}/convertthreed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `convertthreed()` instead.
   *
   * This method doesn't expect any request body.
   */
  convertthreed$Response(params: Convertthreed$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return convertthreed(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `convertthreed$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  convertthreed(params: Convertthreed$Params, context?: HttpContext): Observable<any> {
    return this.convertthreed$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `videorenderControllerCreategif()` */
  static readonly VideorenderControllerCreategifPath = '/video/{id}/creategif';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `creategif()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  creategif$Response(params: Creategif$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return creategif(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `creategif$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  creategif(params: Creategif$Params, context?: HttpContext): Observable<any> {
    return this.creategif$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `videorenderControllerCreatevideo()` */
  static readonly VideorenderControllerCreatevideoPath = '/video/{id}/createvideo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createvideo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createvideo$Response(params: Createvideo$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return createvideo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createvideo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createvideo(params: Createvideo$Params, context?: HttpContext): Observable<any> {
    return this.createvideo$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `videorenderControllerScreenshot()` */
  static readonly VideorenderControllerScreenshotPath = '/video/{id}/screenshot';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `screenshot()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  screenshot$Response(params: Screenshot$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return screenshot(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `screenshot$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  screenshot(params: Screenshot$Params, context?: HttpContext): Observable<any> {
    return this.screenshot$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}
