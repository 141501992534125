/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface Converteps2svg$Params {
  id: string;
  companyId?: string;
  imageurl?: string;
  name?: string;
  centerline?: boolean;
  colorcount?: number;
  corneralwaysthreshold?: number;
  cornersurround?: number;
  cornerthreshold?: number;
  despeckle_level?: number;
}

export function converteps2svg(http: HttpClient, rootUrl: string, params: Converteps2svg$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
  const rb = new RequestBuilder(rootUrl, converteps2svg.PATH, 'post');
  if (params) {
    rb.path('id', params.id, {});
    rb.query('companyId', params.companyId, {});
    rb.query('imageurl', params.imageurl, {});
    rb.query('name', params.name, {});
    rb.query('centerline', params.centerline, {});
    rb.query('colorcount', params.colorcount, {});
    rb.query('corneralwaysthreshold', params.corneralwaysthreshold, {});
    rb.query('cornersurround', params.cornersurround, {});
    rb.query('cornerthreshold', params.cornerthreshold, {});
    rb.query('despeckle_level', params.despeckle_level, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<any>;
    })
  );
}

converteps2svg.PATH = '/video/{id}/converteps2svg';
